import React, { PureComponent } from 'react';
import Helmet from 'react-helmet'
import { navigate  } from 'gatsby'

import Layout from '../containers/Layout';

const title = '愛情的模樣 - 情話產生器'
const description = '這台生產器專門提供一句情話，不管感人的、幽默的、可愛的或是撩人的，希望能給要告白的人一點點靈感與支持！'

class Word extends PureComponent {
  componentDidMount() {
    navigate ('/words', { replace: true, state: { id: this.props.pageContext.id } })
  }

  render() {
    const { pageContext: { id, url } } = this.props;
    return (
      <Layout>
        <Helmet>
          <title>${title}</title>
          <meta property="description" content={description} />
          <meta property="og:url" content={`${url}/words/${id + 1}/`} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={`${url}/${id + 1}.png`} />
        </Helmet>
      </Layout>
    );
  }
}

export default Word;
